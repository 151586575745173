<template>
  <div
    data-collapse="medium"
    data-animation="default"
    data-duration="400"
    role="banner"
    class="navigation-bar w-nav"
  >
    <div class="container nav-narrow">
      <router-link to="/" class="nav-logo mobile w-inline-block"
        ><img
          :src="require('@/assets/images/nav-logo-desktop.png')"
          loading="lazy"
          alt=""
          class="nav-logo-img"
      /></router-link>
      <nav role="navigation" class="nav-menu-row w-nav-menu">
        <router-link to="/" :class="classes('home')"
          >Home</router-link
        >
        <a
          href="https://community.simplicityps.org"
          class="nav-link w-nav-link"
          >forums</a
        >
        <router-link to="/play" :class="classes('play')"
          >download</router-link
        >
        <router-link to="/" class="nav-logo w-inline-block"
          ><img
            :src="require('@/assets/images/nav-logo-desktop.png')"
            loading="lazy"
            height="110"
            alt=""
            class="nav-logo-img"
        /></router-link>
        <router-link to="/vote" :class="classes('vote')"
          >vote</router-link
        >
        <router-link to="/hiscores" :class="classes('hiscores')"
          >hiscores</router-link
        >
        <router-link to="/store" :class="classes('store')"
          >store</router-link
        >
      </nav>
      <div class="menu-button w-nav-button">
        <div class="w-icon-nav-menu"></div>
      </div>
      <div
        id="w-nav-overlay-0"
        class="w-nav-overlay"
        data-wf-ignore=""
        style="display: none"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    let jqueryScript = document.createElement('script');
    jqueryScript.setAttribute(
      'src',
      'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=606eed729bb554f920bf9bc1'
    );
    document.head.appendChild(jqueryScript);

    let simplicityScript = document.createElement('script');
    simplicityScript.setAttribute('src', '/assets/js/simplicity.js');
    document.head.appendChild(simplicityScript);
  },
  methods: {
    classes(route) {
      return route === this.currentRouteName
        ? 'nav-link w-nav-link active'
        : 'nav-link w-nav-link';
    },
    injectScript(url) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      document.head.appendChild(script);
      return script;
    },
  },
};
</script>

<style>
.active {
  border-right-color: #26513e;
  border-left-color: #26513e;
  background-color: rgba(5, 17, 11, 0.43);
  box-shadow: inset 0 0 12px 6px rgb(0 0 0 / 55%);
  color: #5fff11 !important;
}
</style>
