<template>
  <div class="footer-section">
    <div class="container">
      <div class="footer-grid-layout">
        <div class="footer-col-block">
          <div class="maintitle-sub-label">navigation</div>
          <ul role="list" class="footer-nav-list">
            <li class="footer-list-item">
              <router-link to="/" class="footer-link"
                >Home</router-link
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/"
                class="footer-link"
                >Forums</a
              >
            </li>
            <li class="footer-list-item">
              <router-link to="/store" class="footer-link"
                >Store</router-link
              >
            </li>
            <li class="footer-list-item">
              <a href="/vote" class="footer-link">Vote</a>
            </li>
            <li class="footer-list-item">
              <router-link to="/hiscores" class="footer-link"
                >Hiscores</router-link
              >
            </li>
          </ul>
        </div>
        <div class="footer-col-block">
          <div class="maintitle-sub-label">community</div>
          <ul role="list" class="footer-nav-list">
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/30-clans/"
                class="footer-link"
                >Clans</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/57-news-announcements/"
                class="footer-link"
                >Latest News</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/6-update-logs/"
                class="footer-link"
                >Game Updates</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/16-guides/"
                class="footer-link"
                >Guides</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/56-knowledge-base/"
                class="footer-link"
                >Knowledge Base</a
              >
            </li>
          </ul>
        </div>
        <div
          id="w-node-_0056cc8f-3cc8-c987-3e60-24ff99946060-99946037"
          class="footer-col-block center"
        >
          <a href="#" class="footer-logo-link w-inline-block"
            ><img
              :src="
                require('@/assets/images/footer-logo-desktop.png')
              "
              loading="lazy"
              height="135"
              alt=""
              class="footer-logo-img"
          /></a>
          <div class="footer-social-icons">
            <a href="#" target="_blank" class="w-inline-block"
              ><img
                :src="require('@/assets/images/youtube.svg')"
                alt=""
                class="footer-social-icon-img"
            /></a>
            <a
              href="https://discord.com/invite/NjbEDNg"
              target="_blank"
              class="w-inline-block"
              ><img
                :src="require('@/assets/images/discord.svg')"
                alt=""
                class="footer-social-icon-img"
            /></a>
            <a href="#" target="_blank" class="w-inline-block"
              ><img
                :src="require('@/assets/images/facebook.svg')"
                alt=""
                class="footer-social-icon-img"
            /></a>
            <a href="#" target="_blank" class="w-inline-block"
              ><img
                :src="require('@/assets/images/twitter.svg')"
                alt=""
                class="footer-social-icon-img"
            /></a>
          </div>
        </div>
        <div class="footer-col-block">
          <div class="maintitle-sub-label">USEFUL LINKS</div>
          <ul role="list" class="footer-nav-list">
            <li class="footer-list-item">
              <router-link to="/play" class="footer-link"
                >Download</router-link
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://discord.com/invite/NjbEDNg"
                target="_blank"
                class="footer-link"
                >Discord</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/register/"
                class="footer-link"
                >Register</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/login/"
                class="footer-link"
                >Login</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/14-community-media/"
                class="footer-link"
                >Community Media</a
              >
            </li>
          </ul>
        </div>
        <div class="footer-col-block">
          <div class="maintitle-sub-label">discussion</div>
          <ul role="list" class="footer-nav-list">
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/11-general-discussion/"
                class="footer-link"
                >General</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/37-suggestions/"
                class="footer-link"
                >Suggestions</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/64-player-events/"
                class="footer-link"
                >Player Events</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/26-market/"
                class="footer-link"
                >Market</a
              >
            </li>
            <li class="footer-list-item">
              <a
                href="https://community.simplicityps.org/index.php?/forum/12-introductions/"
                class="footer-link"
                >Introductions</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-footer-row">
      <div class="container copyreight-footer-flex">
        <div class="cc-footer-links-grid">
          <a href="#">Terms &amp; Conditions</a>
          <a href="#">Privacy Policy</a>
        </div>
      </div>
    </div>
    <a
      data-w-id="0056cc8f-3cc8-c987-3e60-24ff9994609b"
      href="#top"
      class="back-to-top-arrow w-inline-block"
      ><img
        :src="require('@/assets/images/arrow-up.svg')"
        loading="lazy"
        alt=""
        class="arrow-up"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
};
</script>
