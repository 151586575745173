<template>
  <div>
    <navigation />
    <router-view />
    <footer-view />
  </div>
</template>

<script>
import Navigation from '@/components/Common/Navigation';
import FooterView from '@/components/Common/FooterView';

export default {
  name: 'Main',
  components: { Navigation, FooterView },
};
</script>

<style>
@import url(/resources/css/normalize.css);
@import url(/resources/css/components.css);
@import url(/resources/css/styles.css);
@import url(/resources/css/custom.css);
</style>
